/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'conf';

::-webkit-scrollbar {
  width: 20px;
  background: $dark-gray;

  &:horizontal {
    height: 20px;
  }
}

::-webkit-scrollbar-track {
  border: 4px solid $gray;
}

::-webkit-scrollbar-thumb {
  background: $gray-4;
  border-left: 4px solid $gray;
  border-right: 4px solid $gray;

  &:horizontal {
    border-left: 0;
    border-right: 0;
    border-top: 4px solid $gray;
    border-bottom: 4px solid $gray;
  }
}

body {
  font-family: 'Avant Garde for TVK';
  background: $light-gray-2;
  color: $gray;
}

#svg {
  height: 0;
  position: absolute;
  top: 0;
  left: -99999px;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.company-selector {
  &-refresh {
    cursor: pointer;
    margin: 0 8px;
  }

  &-label {
    font-size: 14px;
    font-weight: 400;
    color: $black;
  }

  &-value {
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }

  &-wrap {
    display: flex;
    align-items: center;

    select {
      margin: 0 8px;
      font-size: 14px;
      font-weight: 400;
      color: $black;
      padding: 4px 0 4px 2px;
      border: 1px solid $black;
      max-width: 120px;
    }
  }
}
